<template>
  <div class="dark:bg-black-darkrounded-lg dark:shadow-block-dark-punch flex justify-center rounded-lg bg-white p-7 py-4 font-bold shadow-block-punch dark:bg-black-dark">
    <div class="w-full p-7">
      <div>
        <Image src="blueprint_zmke74.png" class="mx-auto mb-7 block w-52" alt="Managing Cypress Project" />
        <span class="block pb-14 text-center text-3xl font-bold">Session #4<br>Managing Cypress Project</span>
        <!-- items -->
        <div class="mb-2 flex">
          <div class="w-1/12">
            <span class="mt-2 block h-2 w-2 rounded-full bg-lime" />
          </div>
          <div class="w-11/12">
            <span class="block text-xl font-extrabold">Running Node.js scripts in test</span>
            <span class="text-md font-normal">Seeding database using a predefined script and connecting it to the test flow</span>
          </div>
        </div>
        <div class="mb-2 flex">
          <div class="w-1/12">
            <span class="mt-2 block h-2 w-2 rounded-full bg-blueberry" />
          </div>
          <div class="w-11/12">
            <span class="block text-xl font-extrabold">Switching configuration</span>
            <span class="text-md font-normal">Learn how to switch between different environments</span>
          </div>
        </div>
        <div class="mb-2 flex">
          <div class="w-1/12">
            <span class="mt-2 block h-2 w-2 rounded-full bg-punch" />
          </div>
          <div class="w-11/12">
            <span class="block text-xl font-extrabold">Testing in headless mode</span>
            <span class="text-md font-normal">Running tests in headless mode and explanation of its functionality</span>
          </div>
        </div>
        <div class="mb-2 flex">
          <div class="w-1/12">
            <span class="mt-2 block h-2 w-2 rounded-full bg-tangerine" />
          </div>
          <div class="w-11/12">
            <span class="block text-xl font-extrabold">Setting up a simple CI workflow</span>
            <span class="text-md font-normal">Running a test suite on GitHub actions and collecting results</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
