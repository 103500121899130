<template>
  <div class="my-14">
    <h2 class="mb-7 text-center text-6xl font-extrabold">
      Sessions designed to get you ready<br>for successful testing
    </h2>
    <p class="px-18 my-14 text-center text-2xl font-semibold text-gray-400 lg:text-3xl">
      Learning path that will guide you to your goal
    </p>
    <div class="grid gap-7 md:grid-cols-2">
      <CypressCoreContentModuleOne />
      <CypressCoreContentModuleTwo />
      <CypressCoreContentModuleThree />
      <CypressCoreContentModuleFour />
    </div>
  </div>
</template>
