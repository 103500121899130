<template>
  <div class="my-14 grid justify-items-center text-center">
    <div class="text-xl md:w-3/5">
      <p class="my-7">
        Cypress is getting more and more popularity in developer world and it’s getting traction in test automation teams as well. And for good reason. It is easy to start with and it’s fun to use.
      </p>
      <p class="px-18 mb-7 mt-14 text-center text-2xl font-semibold text-gray-400 lg:text-3xl">
        But at the end of the day, it’s still just a tool.
      </p>
      <p class="my-7">
        Mastering a tool is one thing. Being able to apply it is a whole another story. While applying principles from other tools can give you a good start, it may fall short in trying unleash all the powers of Cypress.io.
      </p>
      <p class="px-18 mt-14 text-center text-2xl font-semibold text-gray-400 lg:text-3xl">
        If you understand how Cypress works, it will take you further than any other tool would.
      </p>
    </div>
  </div>
</template>
