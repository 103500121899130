<template>
  <div>
    <div class="flex items-center justify-center gap-7 rounded-lg bg-ivory-dark p-7 font-bold dark:bg-black-dark">
      <Image src="stamp_prflbz.png" class="block w-28" alt="Cypress stamp of approval" />
      <div>
        <div class=" text-xl">
          This workshop has been endorsed by Cypress DX team
        </div>
        <span class="mt-2 block text-sm font-normal italic">Participants of this workshop get a certificate of completion</span>
      </div>
    </div>
  </div>
</template>
