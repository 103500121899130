<template>
  <div class="my-14 grid justify-items-center text-center">
    <h2 class="my-7 text-5xl font-extrabold md:text-left lg:text-6xl">
      Importance of understanding<br>the core concepts
    </h2>
    <div class="text-xl md:w-3/5">
      <p class="my-7">
        Having the right tool is important as any craftsman, musician or artist will tell you. But that alone is not good enough. Having a direction, vision and ability to perform well goes beyond any tool.
      </p>
      <p class="px-18 my-14 text-center text-2xl font-semibold text-gray-400 lg:text-3xl">
        This is why I built a workshop that puts the usage of a tool into context of testing a real application.
      </p>
    </div>
    <div class="grid w-full gap-7 text-left md:grid-cols-2">
      <div class="bg-ivory-dark p-12 dark:bg-black-dark">
        <h3 class="mb-7 text-3xl font-extrabold">
          Best practices
        </h3>
        <p class="text-lg">
          Why reinvent the wheel? There are tons of problems that have been figured out before and ingenious solutions that you can take inspiration from.
        </p>
      </div>
      <div class="bg-ivory-dark p-12 dark:bg-black-dark">
        <h3 class="mb-7 text-3xl font-extrabold">
          Test design
        </h3>
        <p class="text-lg">
          There’s more to writing a good test than simply emulating a human behavior. Being able to design a test with intention can reduce repetition, uncover regressions, and help you save time and money.
        </p>
      </div>
      <div class="bg-ivory-dark p-12 dark:bg-black-dark">
        <h3 class="mb-7 text-3xl font-extrabold">
          Edge cases
        </h3>
        <p class="text-lg">
          These can be hard to automate because... well, they are edge cases. Understanding Cypress capabilities can help you cover them and reduce the risk of unexpected regressions.
        </p>
      </div>
      <div class="bg-ivory-dark p-12 dark:bg-black-dark">
        <h3 class="mb-7 text-3xl font-extrabold">
          Strategy
        </h3>
        <p class="text-lg">
          Your time is your most valuable asset. Making smart decisions and choosing your battles will be crucial in keeping up with the development. This starts with your first test and your assertions.
        </p>
      </div>
    </div>
  </div>
</template>
