<template>
  <div>
    <h2 class="mt-21 text-center text-6xl font-extrabold">
      Learning experience
    </h2>
    <ContentBlock image="knolling_vrhtct.png">
      <template #paragraph>
        <ul class="list-inside list-disc text-xl">
          <li class="mb-3">
            Workshop will take place online on a Zoom meeting.
          </li>
          <li class="mb-3">
            Every chapter has a demo after which you will be working on a coding challenge so you can apply your knowledge
          </li>
          <li class="mb-3">
            Q&A happens at every chapter so that you can maximize your learning
          </li>
          <li class="mb-3">
            A repository with a real life application and all the learning materials will be available to you, even after the workshop ends
          </li>
          <li class="mb-3">
            In between sessions, I have a Discord server available to you for chat, call or screen sharing
          </li>
          <li class="mb-3">
            Workshop will be in english
          </li>
        </ul>
      </template>
    </ContentBlock>
  </div>
</template>
