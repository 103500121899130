<template>
  <div class="my-14">
    <h2 class=" mb-7 text-center text-6xl font-extrabold">
      F.A.Q
    </h2>
    <h3 class="mt-7 text-2xl font-extrabold">
      Who is this workshop for?
    </h3>
    <p class="text-xl">
      It is especially useful for testers, QAs and QA engineers that are starting with test automation or are migrating from another framework, such as Selenium. It can also be useful for frontend developers that would like to get to know Cypress
    </p>
    <h3 class="mt-7 text-2xl font-extrabold">
      Who is this workshop NOT for?
    </h3>
    <p class="text-xl">
      It’s definitely NOT suitable for people that have no prior experience with web applications. If CSS, HTML and JavaScript are still foreign terms for you, it’s better to first catch up on the basics of these.
    </p>
    <h3 class="mt-7 text-2xl font-extrabold">
      The date of the workshop does not suit me, will there be other options?
    </h3>
    <p class="text-xl">
      Yes! Follow me on <a
        class="prettyLink font-bold"
        href="https://twitter.com/filip_hric/"
      >Twitter</a>, <a
        class="prettyLink font-bold"
        href="https://www.linkedin.com/in/filip-hric-11a5b1126/"
      >LinkedIn</a> or sign up for the newsletter and I’ll let you know.
    </p>
    <h3 class="mt-7 text-2xl font-extrabold">
      I’d like you to come and do a workshop in our company, is that possible?
    </h3>
    <p class="text-xl">
      Of course! I’m preparing an info page about this, but feel free to reach out to me and I’d be happy to give you more info
    </p>
    <h3 class="mt-7 text-2xl font-extrabold">
      Will the workshop be recorded?
    </h3>
    <p class="text-xl">
      I don’t like to record workshop for various reasons. The main one is that a recording will not have the same value as when you attend the workshop yourself. You’ll miss the practical examples, option to ask questions and interact with other attendees.
    </p>
  </div>
</template>
