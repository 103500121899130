<template>
  <div class="grid grid-cols-1 place-content-center gap-7 bg-ivory-dark py-14 dark:bg-black md:mb-3 md:grid-cols-2">
    <div class="place-self-center self-center">
      <div class="overflow-hidden rounded-3xl bg-white shadow-block-lime dark:bg-transparent dark:shadow-block-none md:w-full">
        <Image src="/small_square_kdhln0.png" alt="Filip Hric" class="block w-64" />
      </div>
    </div>
    <div class="self-center">
      <h2 class="mb-7 text-center text-5xl font-extrabold md:text-left xl:text-6xl">
        Hey, I’m Filip Hric
      </h2>
      <p class="p-7 text-xl md:pl-0 md:pr-7">
        I have conducted dozens of workshops worldwide and have been teaching how to use Cypress since version 4.4.0. I do my best to take complex concepts and break them down into simple explanations. I make improvements and update this workshop regularly, so you will always get the latest and greatest. My goal is to provide attendees with a firm knowledge of the core principles that you can apply in your next job or next project.
      </p>
    </div>
  </div>
</template>
