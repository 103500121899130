<template>
  <div class="dark:bg-black-darkrounded-lg dark:shadow-block-dark-lime flex justify-center rounded-lg bg-white p-7 py-4 font-bold shadow-block-lime dark:bg-black-dark">
    <div class="w-full p-7">
      <div>
        <Image src="tricycle_whgyxo.png" class="mx-auto mb-7 block w-52" alt="Understanding basics" />
        <span class="block pb-14 text-center text-3xl font-bold">Session #1<br>Understanding basics</span>
        <!-- items -->
        <div class="mb-2 flex">
          <div class="w-1/12">
            <span class="mt-2 block h-2 w-2 rounded-full bg-lime" />
          </div>
          <div class="w-11/12">
            <span class="block text-xl font-extrabold">Creating your first Cypress project</span>
            <span class="text-md font-normal">Installation and project structure, running Cypress GUI</span>
          </div>
        </div>
        <div class="mb-2 flex">
          <div class="w-1/12">
            <span class="mt-2 block h-2 w-2 rounded-full bg-blueberry" />
          </div>
          <div class="w-11/12">
            <span class="block text-xl font-extrabold">Opening the application</span>
            <span class="text-md font-normal">.visit() command, basic configuration, effective work with browser</span>
          </div>
        </div>
        <div class="mb-2 flex">
          <div class="w-1/12">
            <span class="mt-2 block h-2 w-2 rounded-full bg-punch" />
          </div>
          <div class="w-11/12">
            <span class="block text-xl font-extrabold">Selecting elements on page</span>
            <span class="text-md font-normal">Ways of selecting elements on page and selector strategies</span>
          </div>
        </div>
        <div class="mb-2 flex">
          <div class="w-1/12">
            <span class="mt-2 block h-2 w-2 rounded-full bg-tangerine" />
          </div>
          <div class="w-11/12">
            <span class="block text-xl font-extrabold">Writing your first test</span>
            <span class="text-md font-normal">Basic scenario automation, debugging and reading error messages</span>
          </div>
        </div>
        <div class="mb-2 flex">
          <div class="w-1/12">
            <span class="mt-2 block h-2 w-2 rounded-full bg-cheese" />
          </div>
          <div class="w-11/12">
            <span class="block text-xl font-extrabold">Making assertions</span>
            <span class="text-md font-normal">Best practices for writing assertions in end-to-end tests</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
